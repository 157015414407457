<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('nom')"
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.slug"
            :error-messages="slugErrors"
            :label="$t('slug')"
            @input="$v.item.slug.$touch()"
            @blur="$v.item.slug.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.iconAffichage"
            :error-messages="iconAffichageErrors"
            :label="$t('iconAffichage')"
            @input="$v.item.iconAffichage.$touch()"
            @blur="$v.item.iconAffichage.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.couleurLabel"
            :error-messages="couleurLabelErrors"
            :label="$t('couleurLabel')"
            @input="$v.item.couleurLabel.$touch()"
            @blur="$v.item.couleurLabel.$touch()"
          />
        </v-col>
      </v-row>
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'IntervenantTypeForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
  },
  data() {
    return {
        nom: null,
        slug: null,
        iconAffichage: null,
        couleurLabel: null,
    };
  },
  computed: {

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, 'slug') && errors.push(this.violations.slug);


      return errors;
    },
    iconAffichageErrors() {
      const errors = [];

      if (!this.$v.item.iconAffichage.$dirty) return errors;

      has(this.violations, 'iconAffichage') && errors.push(this.violations.iconAffichage);


      return errors;
    },
    couleurLabelErrors() {
      const errors = [];

      if (!this.$v.item.couleurLabel.$dirty) return errors;

      has(this.violations, 'couleurLabel') && errors.push(this.violations.couleurLabel);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
  },
  validations: {
    item: {
      nom: {
      },
      slug: {
      },
      iconAffichage: {
      },
      couleurLabel: {
      },
    }
  }
};
</script>
